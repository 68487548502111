// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import './jquery.js'
import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts

window.jQuery = $
window.$ = $

// Ensuite importer les autres dépendances
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import Cookie from "js-cookie"
import('select2');

// jQuery UI doit venir après jQuery est globalement disponible
import "jquery-ui";
import "bootstrap"
// import "packs/gift_cards/form.js"
// import "packs/gift_cards/stripe.js"
// import "packs/gift_prestas/form.js"
// import "packs/gift_prestas/stripe.js"

require("trix")
require("@rails/actiontext")

Turbolinks.start()
ActiveStorage.start()
import { smoothScrollAnchor } from "smooth-scroll-anchor";
import Parallax from 'scroll-parallax';
import ScrollReveal from 'scrollreveal'


// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel';

const copyText = (element) => {
  const baseText = element.parentElement.querySelector("input").value;
  element.parentElement.querySelector("input").select();
  document.execCommand('copy');
  element.parentElement.querySelector("input").value = 'Copié dans le presse papier';
  setTimeout(() => {
    element.parentElement.querySelector("input").value = baseText;
  }, 1500);
};


window.copyText = copyText;
require("trix")
require("@rails/actiontext")

document.addEventListener('turbolinks:load', () => {
  console.log('turbolinks:load')
})

document.addEventListener('turbo:load', () => {
  console.log('turbo:load')
})
document.addEventListener('turbolinks:load', () => {
  new Parallax('.parallax', {
    center: 1,
    intensity: 30
  }).init();

  // $('.owl-carousel').owlCarousel({
  //   autoWidth: true,
  //   center: true,
  //   margin: 120,
  //   loop: true,
  //   items: 6,
  //   autoplay: true
  // });

  $('.carousel').carousel({
    interval: 2000
  })
  const jQuery = $
  jQuery(function(){
    jQuery("#bgndVideo").YTPlayer();
    $("#bgndVideo").YTPApplyFilters({
      grayscale: 30,
      brightness: 70
    })
  });
});
document.addEventListener('turbolinks:load', () => {

  $('#navbarNavAltMarkup').on('show.bs.collapse', function (event) {
    event.target.parentElement.parentElement.classList.add('bg-scrolled');
  })

  $('#navbarNavAltMarkup').on('hide.bs.collapse', function (event) {
    if($(window).scrollTop() < 10){
      event.target.parentElement.parentElement.classList.remove('bg-scrolled');
    }
  })

  const bElement = document.querySelector('body')
  const paths = bElement.dataset.bgImages.split(',')
  if(bElement.dataset.switch == 'true'){
    bElement.style.backgroundImage = "url("+paths[1]+")";
    setInterval(() => {
      let indx = parseInt(bElement.dataset.bgIndex)
      let current_path = paths[indx]
      console.log(current_path)
      bElement.style.backgroundImage = "url("+current_path+")";
      bElement.dataset.bgIndex = indx == 0 ? 1 : 0
    }, 10000)
  }

  // ScrollReveal().reveal('.sr-left', { delay: 500, origin: 'left', distance: '200px', reset: true, scale: 0.5  });
  // ScrollReveal().reveal('.sr-right', { delay: 500, origin: 'right', distance: '200px', reset: true, scale: 0.5  });
  // ScrollReveal().reveal('.sr-bottom', { delay: 500, origin: 'bottom', distance: '200px', reset: true, scale: 0.5  });

  new Parallax('.parallax', {
    center: 1,
    intensity: 30
  }).init();

  // $('.owl-carousel').owlCarousel({
  //   autoWidth: true,
  //   center: true,
  //   margin: 120,
  //   loop: true,
  //   items: 6,
  //   autoplay: true
  // });

  $('.carousel').carousel({
    interval: 2000
  })

  smoothScrollAnchor({
      behaviour: "smooth", // [smooth | auto] Smooth animated scroll or instant
      block: "start", // [start | center | end | nearest] Alignment of the target element when it's finished scrolling
      offset: 56 // Optional offset of the target scroll position. Handy if you have a fixed header!
    }
  );
  // manualScroll(window.location.hash);
  var btn = $('#button');

  $(window).scroll(function() {
    if ($(window).scrollTop() > 300) {
      btn.addClass('show');
    } else {
      btn.removeClass('show');
    }
  });

  btn.on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop:0}, '300');
  });

  var startBtn = $('#home-discover');
  startBtn.on('click', function(e) {
    e.preventDefault();
    console.log(document.querySelector("#home-discover").scrollTop)
    $('html, body').animate({scrollTop: document.querySelector("#home-description").getBoundingClientRect().top - 150}, '50');
  });


  const cookieBar = new CookieBar();
  cookieBar.init();

});

class CookieBar {
  constructor() {
    this.cookiesBar = document.getElementById('cookies-bar');
  }

  init() {
    if (this.cookiesAllowed()) {
      this.appendGACode();
    }

    this.addButtonBehaviors();
  }

  cookiesAllowed() {
    return Cookies.get('allow_cookies') === 'yes';
  }

  addButtonBehaviors() {
    if (!this.cookiesBar) {
      return;
    }

    this.cookiesBar.querySelector('.accept').addEventListener('click', () => this.allowCookies(true));

    this.cookiesBar.querySelector('.reject').addEventListener('click', () => this.allowCookies(false));
  }

  appendGACode() {
    const ga = "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){" +
      "(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o)," +
      "m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)" +
      "})(window,document,'script','//www.google-analytics.com/analytics.js','ga');" +
      "ga('create', 'UA-XXXXX-Y', 'auto');" + "ga('send', 'pageview');";

    document.getElementsByTagName('head')[0].append('<script>' + ga + '</script>');
  }

  allowCookies(allow) {
    if (allow) {
      Cookies.set('allow_cookies', 'yes', {
        expires: 365
      });

      this.appendGACode();
    } else {
      Cookies.set('allow_cookies', 'no', {
        expires: 365
      });
    }

    this.cookiesBar.classList.add('hidden');
  }
}

window.onload = function() {
  $('body').bind('copy paste', function(e) {
    e.preventDefault();
    alert("Ce site est sous ©copyright. La copie de son contenu est interdite.");
  });

  const cookieBar = new CookieBar();
  cookieBar.init();
};

window.addEventListener('scroll', function(e) {
  document.querySelectorAll('.navbar').forEach(function(el) {
    if($(window).scrollTop() === 0) {
      el.classList.remove('bg-scrolled');
    } else {
      el.classList.add('bg-scrolled');
    }
  })
})




// window.addEventListener('contextmenu', function(event){
//   event.preventDefault();
//   alert("Ce site est sous ©copyright. La copie de son contenu est interdite.");
// });



// setInterval(() => {
//   let cont = document.getElementById('marks');
//   let html = cont.querySelector('img:nth-of-type(1)');
//   var img = document.createElement("img");
//   img.src = html.attributes.src.value;
//   html.style.height = '0px';
//   setTimeout(() => {
//     html.remove();
//   }, 1000);
//   cont.appendChild(img);
//   img.style.height = '0px';
//   img.classList = "d-block mx-1 my-2"
//   setTimeout(() => {
//     img.style.height = '100px';
//   }, 2000)
// }, 7000);